import * as Yup from "yup"

const REQUIRED_MESSAGE = "This field is required"
const VALID_EMAIL = "Please input a valid email"
const VALID_CARD_NUMBER =
  "Please input a valid card number in this format: 1234 5678 9876 5432"
const VALID_MOBILE_NUMBER =
  "Please input a valid mobile number in this format: 09991234567"
const VALID_LANDLINE_NUMBER =
  "Please input a valid mobile number in this format: 0287001111"
const VALID_BLOOD_PRESSURE =
  "Please input a valid blood pressure in this format: 120/80"
const VALID_ADDRESS_DETAILS = "Invalid address details"

export const getDefaultValidation = ({ field }) => {
  let fieldValidation
  switch (field.type) {
    case "checkbox":
      fieldValidation = Yup.array()
      break

    case "schedule":
      fieldValidation = Yup.object()

      for (let objectField of field.fieldNames) {
        fieldValidation = fieldValidation.concat(
          fieldValidation.shape({
            [objectField]: Yup.string().required(REQUIRED_MESSAGE),
          })
        )
      }

      break

    case "select":
      fieldValidation = Yup.object().shape({
        value: Yup.string().when("label", {
          is: () => !!field.required && !field.isFollowUpQuestion,
          then: Yup.string().required(REQUIRED_MESSAGE),
          otherwise: Yup.string(),
        }),
      })
      break

    case "multiselect":
      fieldValidation = Yup.array()
        .of(
          Yup.object().shape({
            value: Yup.string().when("label", {
              is: () => !!field.required,
              then: Yup.string().required(REQUIRED_MESSAGE),
              otherwise: Yup.string(),
            }),
          })
        )
        .min(field?.min || 1, REQUIRED_MESSAGE)
      break

    case "date":
      fieldValidation = Yup.object()
      break

    case "upload":
      fieldValidation = Yup.object()

      if (field?.fieldNames?.length > 0)
        for (let objectField of field.fieldNames) {
          fieldValidation = fieldValidation.concat(
            fieldValidation.shape({
              [objectField]: Yup.object().shape({
                name: !!field.required
                  ? Yup.string().required(REQUIRED_MESSAGE)
                  : Yup.string(),
              }),
            })
          )
        }
      else {
        fieldValidation = Yup.array()
          .required(REQUIRED_MESSAGE)
          .min(1, REQUIRED_MESSAGE)
          .nullable()
      }
      break

    case "address":
      let shouldInputSite = field.fieldNames.includes(`${field.name}.siteName`)
      fieldValidation = Yup.object().shape({
        city: Yup.object().when("addressType", {
          is: (addressType) =>
            !!field.required &&
            (!shouldInputSite || (shouldInputSite && addressType === "Home")),
          then: Yup.object().shape({
            value: Yup.string().required(REQUIRED_MESSAGE),
          }),
          otherwise: Yup.object().shape({
            value: Yup.string(),
          }),
        }),
        barangay: Yup.object().when("addressType", {
          is: (addressType) =>
            !!field.required &&
            (!shouldInputSite || (shouldInputSite && addressType === "Home")),
          then: Yup.object().shape({
            value: Yup.string().trim().required(REQUIRED_MESSAGE),
          }),
          otherwise: Yup.object().shape({
            value: Yup.string(),
          }),
        }),
        province: Yup.object().when("addressType", {
          is: (addressType) =>
            !!field.required &&
            (!shouldInputSite || (shouldInputSite && addressType === "Home")),
          then: Yup.object().shape({
            value: Yup.string().required(REQUIRED_MESSAGE),
          }),
          otherwise: Yup.object().shape({
            value: Yup.string(),
          }),
        }),
        siteName: Yup.object().when("addressType", {
          is: (addressType) =>
            !!field.required && shouldInputSite && addressType === "Office",
          then: Yup.object().shape({
            value: Yup.string().required(REQUIRED_MESSAGE),
          }),
          otherwise: Yup.object().shape({
            value: Yup.string(),
          }),
        }),
        streetAddress: Yup.string().when("addressType", {
          is: (addressType) =>
            !!field.required &&
            (!shouldInputSite || (shouldInputSite && addressType === "Home")),
          then: Yup.string()
            .trim()
            .test({
              name: "not-na",
              message: VALID_ADDRESS_DETAILS,
              test: (value) =>
                value
                  ? value.toLowerCase() !== "n/a" &&
                    value.toLowerCase() !== "na"
                  : true,
            })
            .required(REQUIRED_MESSAGE),
          otherwise: Yup.string(),
        }),
        houseNumber: Yup.string().when("addressType", {
          is: (addressType) => !!field.required,
          then: Yup.string()
            .trim()
            .test({
              name: "not-na",
              message: VALID_ADDRESS_DETAILS,
              test: (value) =>
                value
                  ? value.toLowerCase() !== "n/a" &&
                    value.toLowerCase() !== "na"
                  : true,
            })
            .matches(/^(?!\s*$).+/, REQUIRED_MESSAGE)
            .required(REQUIRED_MESSAGE),
          otherwise: Yup.string(),
        }),
        addressType: Yup.string().when("value", {
          is: () => !!field.required,
          then: Yup.string().required(REQUIRED_MESSAGE),
          otherwise: Yup.string(),
        }),
        notes: Yup.string(),
      })
      break

    case "number":
    case "text":
    case "radio":
    case "textarea":
    default:
      fieldValidation = Yup.string().matches(/.*\S.*/, REQUIRED_MESSAGE)

      if (field?.validation?.includes("Email"))
        fieldValidation = fieldValidation.concat(
          fieldValidation.email(VALID_EMAIL)
        )
      if (field?.validation?.includes("Mobile Number"))
        fieldValidation = fieldValidation.concat(
          fieldValidation
            .min(11, VALID_MOBILE_NUMBER)
            .max(11, VALID_MOBILE_NUMBER)
            .matches(/^09[0-9]{9}$/, VALID_MOBILE_NUMBER)
        )
      if (field?.validation?.includes("Blood Pressure"))
        fieldValidation = fieldValidation.concat(
          fieldValidation.matches(/^\d{2,3}\/\d{2,3}$/, VALID_BLOOD_PRESSURE)
        )

      if (field?.validation?.includes("Landline Number"))
        fieldValidation = fieldValidation.concat(
          fieldValidation.matches(/^0[1-5]{1}[0-9]{8}$/, VALID_LANDLINE_NUMBER)
        )

      if (field?.name === "maxicareCardNumber")
        fieldValidation = fieldValidation.concat(
          fieldValidation.matches(
            /^\d{4} \d{4} \d{4} \d{4}$/,
            VALID_CARD_NUMBER
          )
        )
      break
  }

  return fieldValidation
}

export const getCustomValidation = ({ field, fieldValidation }) => {
  if (!!field.required) {
    if (!!field.referenceAnswer) {
      let referenceQuestion = field.referenceQuestionName[0]

      if (field?.type === "date") {
        fieldValidation = fieldValidation.concat(
          fieldValidation.when(referenceQuestion, {
            is: (referenceAnswer) =>
              referenceAnswer === field?.referenceAnswer ||
              referenceAnswer?.includes(field?.referenceAnswer),
            then: fieldValidation.shape({
              month: Yup.object().shape({
                value: Yup.string().when("label", {
                  is: () => !!field.required,
                  then: Yup.string().required(REQUIRED_MESSAGE),
                  otherwise: Yup.string(),
                }),
              }),
              date: Yup.object().shape({
                value: Yup.string().when("label", {
                  is: () => !!field.required,
                  then: Yup.string().required(REQUIRED_MESSAGE),
                  otherwise: Yup.string(),
                }),
              }),
              year: Yup.string().when("value", {
                is: () => !!field.required,
                then: Yup.string().required(REQUIRED_MESSAGE),
                otherwise: Yup.string(),
              }),
            }),
          })
        )
      } else
        fieldValidation = fieldValidation.concat(
          fieldValidation.when(referenceQuestion, {
            is: (referenceAnswer) =>
              referenceAnswer === field?.referenceAnswer ||
              referenceAnswer?.includes(field?.referenceAnswer),
            then: fieldValidation.required(REQUIRED_MESSAGE),
          })
        )
    } else {
      if (field?.type === "date")
        fieldValidation = fieldValidation.concat(
          fieldValidation.shape({
            month: Yup.object().shape({
              value: Yup.string().when("label", {
                is: () => !!field.required,
                then: Yup.string().required(REQUIRED_MESSAGE),
                otherwise: Yup.string(),
              }),
            }),
            date: Yup.object().shape({
              value: Yup.string().when("label", {
                is: () => !!field.required,
                then: Yup.string().required(REQUIRED_MESSAGE),
                otherwise: Yup.string(),
              }),
            }),
            year: Yup.string().when("value", {
              is: () => !!field.required,
              then: Yup.string().required(REQUIRED_MESSAGE),
              otherwise: Yup.string(),
            }),
          })
        )
      else
        fieldValidation = fieldValidation.concat(
          fieldValidation.required(REQUIRED_MESSAGE)
        )
    }

    if (!!field.minLength) {
      fieldValidation = fieldValidation.concat(
        fieldValidation.min(field.minLength, REQUIRED_MESSAGE)
      )
    }
  }

  return fieldValidation
}

export const createValidationSchema = ({ fields }) => {
  let validationSchema = Yup.object()

  for (let field of fields) {
    let fieldValidation = getDefaultValidation({ field })
    fieldValidation = getCustomValidation({ field, fieldValidation })

    validationSchema = validationSchema.concat(
      Yup.object().shape({
        [field.name]: fieldValidation,
      })
    )
  }

  return validationSchema
}
